import Vue from 'vue';
import VueRouter from 'vue-router';
import Ping from '../components/Ping.vue';
import HDB from '../components/HDB.vue';
import Mappy from '../components/Mappy.vue';
import Chart from '../components/Chart.vue';
import Transactions from '../components/Transactions.vue';

// eslint-disable linebreak-style
Vue.use(VueRouter);

const routes = [
/*
  {
    path: '/',
    name: 'Home',
    component: Home,
  },
*/
  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'),
  },
  {
    path: '/ping',
    name: 'Ping',
    component: Ping,
  },
  {
    path: '/',
    name: 'HDB',
    component: HDB,
  },
  {
    path: '/mappy',
    name: 'Mappy',
    component: Mappy,
  },
  {
    path: '/chart',
    name: 'Chart',
    component: Chart,
  },
  {
    path: '/transactions',
    name: 'Transactions',
    component: Transactions,
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
