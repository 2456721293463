<template>
  <div class='gmap'>
      <h2>{{location}}</h2>
      <p>Location Map</p>
      <!--
      <label>
        <gmap-autocomplete
          @place_changed="setPlace">
        </gmap-autocomplete>
        <button @click="addMarker">Add</button>
      </label>
      -->
    <gmap-map
      :center="center"
      :zoom="zoom"
      :options="options"
      style='width:100%;  height: 400px;'
    >
      <gmap-marker
        :key="index"
        v-for="(m, index) in markers"
        :position="m.position"
        @click="center=m.position"
      ></gmap-marker>
    </gmap-map>

  </div>
</template>

<script>
export default {
  name: 'Mappy',
  data() {
    return {
      // default to Singapore to keep it simple
      // change this to whatever makes sense
      center: { lat: this.$store.state.latitude, lng: this.$store.state.longtitude },
      zoom: 11,
      markers: [],
      places: [],
      currentPlace: null,
      locations: [],
      formatedAddresses: [],
      options: {
        styles: [
          { elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
          { elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
          {
            featureType: 'administrative.locality',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'geometry',
            stylers: [{ color: '#263c3f' }],
          },
          {
            featureType: 'poi.park',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#6b9a76' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry',
            stylers: [{ color: '#38414e' }],
          },
          {
            featureType: 'road',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#212a37' }],
          },
          {
            featureType: 'road',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#9ca5b3' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry',
            stylers: [{ color: '#746855' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'geometry.stroke',
            stylers: [{ color: '#1f2835' }],
          },
          {
            featureType: 'road.highway',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#f3d19c' }],
          },
          {
            featureType: 'transit',
            elementType: 'geometry',
            stylers: [{ color: '#2f3948' }],
          },
          {
            featureType: 'transit.station',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#d59563' }],
          },
          {
            featureType: 'water',
            elementType: 'geometry',
            stylers: [{ color: '#17263c' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.fill',
            stylers: [{ color: '#515c6d' }],
          },
          {
            featureType: 'water',
            elementType: 'labels.text.stroke',
            stylers: [{ color: '#17263c' }],
          },
        ],
      },
    };
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    location() {
      return this.$store.state.location;
    },
    latitude() {
      return this.$store.state.latitude;
    },
    longtitude() {
      return this.$store.state.longtitude;
    },
  },
  watch: {
    latitude() {
      this.zoomlocate();
    },
    longtitude() {
      this.zoomlocate();
    },
  },
  mounted() {
    // this.geolocate();
    this.initiallocate();
  },

  methods: {
    // receives a place object via the autocomplete component
    setPlace(place) {
      this.currentPlace = place;
    },
    add_latitude(latitude) {
      this.$store.dispatch('ADD_LATITUDE', latitude);
    },
    add_longtitude(longtitude) {
      this.$store.dispatch('ADD_LONGTITUDE', longtitude);
    },
    addMarker() {
      if (this.currentPlace) {
        const marker = {
          lat: this.currentPlace.geometry.location.lat(),
          lng: this.currentPlace.geometry.location.lng(),
        };
        this.markers.push({ position: marker });
        this.places.push(this.currentPlace);
        this.center = marker;
        this.currentPlace = null;
      }
    },
    geolocate() {
      navigator.geolocation.getCurrentPosition((position) => {
        this.center = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };
      });
    },

    initiallocate() {
      this.center = {
        lat: this.$store.state.latitude,
        lng: this.$store.state.longtitude,
      };
    },

    zoomlocate() {
      /* this.markers.push({
        position: {
          lat: this.$store.state.latitude,
          lng: this.$store.state.longtitude,
        },
      }); */
      this.markers = [{
        position: {
          lat: this.$store.state.latitude,
          lng: this.$store.state.longtitude,
        },
      }];
      this.center = {
        lat: this.$store.state.latitude,
        lng: this.$store.state.longtitude,
      };
      this.zoom = 12.5;
    },
  },
};
</script>
