import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import BootstrapVue from 'bootstrap-vue';
import Vue from 'vue';
import 'es6-promise/auto';
import Vuex from 'vuex';
import VueAnalytics from 'vue-analytics';
import * as VueGoogleMaps from 'vue2-google-maps';
import router from './router';
import App from './App.vue';

Vue.use(BootstrapVue);
Vue.config.productionTip = false;

Vue.use(Vuex);

const store = new Vuex.Store({
  state: {
    count: 0,
    location: 'SINGAPORE',
    latitude: 1.3525844,
    longtitude: 103.8213148,
    chartdatas: {
      labels: [2018, 2019, 2020, 2021], '2 ROOM': [230000.0, 225000.0, 230000.0, 252500.0], '3 ROOM': [290000.0, 283000.0, 295000.0, 320000.0], '4 ROOM': [400000.0, 400000.0, 420000.0, 450000.0], '5 ROOM': [475000.0, 482500.0, 508844.0, 545000.0], EXECUTIVE: [610000.0, 595000.0, 618000.0, 660888.0], '1 ROOM': [180000.0, 173268.0, 174000.0, 190000.0], 'MULTI-GENERATION': [780000.0, 819444.0, 762500.0, 860000.0],
    },
  },
  mutations: {
    LOCATION_MUTATION(state, newlocation) {
      state.location = newlocation;
    },
    LATITUDE_MUTATION(state, newlatitutde) {
      state.latitude = newlatitutde;
    },
    LONGTITUDE_MUTATION(state, newlongtitude) {
      state.longtitude = newlongtitude;
    },
    CHART_MUTATION(state, newchart) {
      state.chartdatas = newchart;
    },
  },

  actions: {
    ADD_LOCATION({ commit }, newlocation) {
      commit('LOCATION_MUTATION', newlocation);
    },
    ADD_LATITUDE({ commit }, newlatitutde) {
      commit('LATITUDE_MUTATION', newlatitutde);
    },
    ADD_LONGTITUDE({ commit }, newlongtitude) {
      commit('LONGTITUDE_MUTATION', newlongtitude);
    },
    ADD_CHART({ commit }, newchart) {
      commit('CHART_MUTATION', newchart);
    },
  },

  getters: {
    getLatitude(state) {
      return state.latitude;
    },
    getLongtitude(state) {
      return state.longtitude;
    },
    getChartdata(state) {
      return state.chartdatas;
    },
  },
});

Vue.use(VueAnalytics, {
  id: 'UA-180585138-1',
  router,
});

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyBg-vRHD2Dp3LKbhFLPtoInvmp6fxtoy8A',
    libraries: ['places', 'geocoder'], // necessary for places input
  },
});

new Vue({
  router,
  store,
  methods: {
    increment() {
      this.$store.commit('increment');
    },
  },
  render: (h) => h(App),
}).$mount('#app');
