<template>
  <div class="Chart">
      <h2>{{location}}</h2>
      <p>Median Resale Price</p>
    <line-chart :chart-data="datacollection" :options="chartOptions"></line-chart>
    <!--<button @click="fillData()">Randomize</button>-->
  </div>
</template>

<script>
import LineChart from './LineChart';

export default {
  components: {
    LineChart,
  },
  data() {
    return {
      gradient: [null, null, null, null, null, null, null],
      datacollection: null,
      chartOptions: {
        responsive: true,
        maintainAspectRatio: false,
      },
    };
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
    location() {
      return this.$store.state.location;
    },
    chartdatas() {
      return this.$store.state.chartdatas;
    },
  },
  watch: {
    chartdatas() {
      this.fillData();
    },
  },
  mounted() {
    this.fillData();
    this.gradient[0] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient[1] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient[2] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient[3] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient[4] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient[5] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);
    this.gradient[6] = this.$refs.canvas.getContext('2d').createLinearGradient(0, 0, 0, 450);

    this.gradient[0].addColorStop(0, 'rgba(255, 0,0, 0.5)');
    this.gradient[0].addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    this.gradient[0].addColorStop(1, 'rgba(255, 0, 0, 0)');

    this.gradient[1].addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    this.gradient[1].addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    this.gradient[1].addColorStop(1, 'rgba(0, 231, 255, 0)');

    this.gradient[2].addColorStop(0, 'rgba(255, 0,0, 0.5)');
    this.gradient[2].addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    this.gradient[2].addColorStop(1, 'rgba(255, 0, 0, 0)');

    this.gradient[3].addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    this.gradient[3].addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    this.gradient[3].addColorStop(1, 'rgba(0, 231, 255, 0)');

    this.gradient[4].addColorStop(0, 'rgba(255, 0,0, 0.5)');
    this.gradient[4].addColorStop(0.5, 'rgba(255, 0, 0, 0.25)');
    this.gradient[4].addColorStop(1, 'rgba(255, 0, 0, 0)');

    this.gradient[5].addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    this.gradient[5].addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    this.gradient[5].addColorStop(1, 'rgba(0, 231, 255, 0)');

    this.gradient[6].addColorStop(0, 'rgba(0, 231, 255, 0.9)');
    this.gradient[6].addColorStop(0.5, 'rgba(0, 231, 255, 0.25)');
    this.gradient[6].addColorStop(1, 'rgba(0, 231, 255, 0)');
  },
  methods: {
    add_chart_data(chartdatas) {
      this.$store.dispatch('ADD_CHART', chartdatas);
    },
    fillData() {
      const datasetss = [];
      const colors = ['rgba(189, 195, 199, 1)', 'rgba(142, 68, 173, 1)', 'rgba(41, 128, 185, 1)', 'rgba(39, 174, 96, 1)', 'rgba(22, 160, 133, 1)', 'rgba(241, 196, 15, 1)', 'rgba(231, 76, 60, 1)', 'rgba(236, 240, 241, 1)'];
      const chartsy = this.$store.state.chartdatas;
      Object.keys(chartsy).forEach((key, index) => {
        if (key !== 'labels') {
          datasetss.push({
            label: key,
            borderColor: colors[index],
            pointBackgroundColor: 'white',
            borderWidth: 2,
            pointBorderColor: 'white',
            backgroundColor: (`${colors[index].slice(0, -2)}0.1)`), // this.gradient[index],
            data: this.$store.state.chartdatas[key],
          });
        }
      });
      this.datacollection = {
        labels: this.$store.state.chartdatas.labels,
        datasets: datasetss,
      };
    },
  },
};
</script>
