<template>
<!-- eslint-disable max-len -->
<!-- eslint-disable no-mixed-spaces-and-tabs -->
<!-- eslint-disable no-tabs -->
  <div class="container">
    <div class="row">
      <div class="col-sm-12">
        <img alt="HDB Pricer logo" src="../assets/hdbpricer.png" >
        <hr />
      </div>
        <div class="col-sm-5">
            <b-form-group
              label="Filter"
              label-for="filter-input"
              label-cols-sm="1"
              label-align-sm="left"
              label-size="sm"
              class="mb-0"
            >
              <b-input-group size="sm">
                <b-form-input
                  id="filter-input"
                  v-model="filter"
                  type="search"
                  placeholder="Type to Search"
                ></b-form-input>

                <b-input-group-append>
                  <b-button :disabled="!filter" @click="filter = ''">Clear</b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
        </div>
        <div class="col-sm-1">
        </div>
        <div class="col-sm-2">
            <b-form-group
              label="Per page"
              label-for="per-page-select"
              label-cols-sm="6"
              label-align-sm="right"
              label-size="sm"
            >
              <b-form-select
                id="per-page-select"
                v-model="perPage"
                :options="pageOptions"
                size="sm"
              ></b-form-select>
            </b-form-group>
      </div>
      <div class="col-sm-4">
          <b-pagination
            v-model="currentPage"
            :total-rows="totalRows"
            :per-page="perPage"
            align="fill"
            size="sm"
            class="my-0"
          ></b-pagination>
      </div>
        <br /><br />
        <div class="col-sm-12">
          <b-table
            :items="transactions"
            :fields="fields"
            :sort-by.sync="sortBy"
            :sort-desc.sync="sortDesc"
            :dark="true"
            :filter="filter"
            :per-page="perPage"
            :current-page="currentPage"
            responsive="sm"
            sticky-header
            small
            striped
            hover
            sort-icon-right
            @filtered="onFiltered"
          >
          </b-table>
          <div>
            Sorted By: <b>{{ sortBy }}</b>, Sort Direction:
            <b>{{ sortDesc ? 'Descending' : 'Ascending' }}</b>
            <br /><br />
          </div>
        </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios';

export default {
  data() {
    return {
      transactions: [],
      variants: ['primary', 'secondary', 'success', 'warning', 'danger', 'info', 'light', 'dark'],
      headerBgVariant: 'dark',
      headerTextVariant: 'light',
      bodyBgVariant: 'light',
      bodyTextVariant: 'dark',
      footerBgVariant: 'warning',
      footerTextVariant: 'dark',
      sortBy: 'month',
      sortDesc: true,
      filter: '',
      filterOn: [],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      pageOptions: [10, 50, 100, 500, { value: 2000, text: 'Show a lot' }],
      fields: [
        { key: 'town', sortable: true },
        { key: 'flat_type', sortable: true },
        { key: 'flat_model', sortable: true },
        { key: 'floor_area_sqm', sortable: true },
        { key: 'street_name', sortable: true },
        { key: 'month', sortable: true },
        { key: 'remaining_lease', sortable: true },
        { key: 'lease_commence_date', sortable: true },
        { key: 'storey_range', sortable: true },
        { key: 'block', sortable: true },
        {
          // A regular column with custom formatter
          key: 'resale_price',
          sortable: true,
          formatter: 'formatPrice',
        },
      ],
    };
  },
  computed: {
    count() {
      return this.$store.state.count;
    },
  },
  methods: {
    getTransactions() {
      const path = 'https://data.gov.sg/api/action/datastore_search?resource_id=f1765b54-a209-4718-8d38-a39237f502b3&sort=month%20desc&limit=2000';
      axios
        .get(path)
        .then((res) => {
          this.transactions = res.data.result.records;
          this.totalRows = this.transactions.length;
        })
        .catch((error) => {
          // eslint-disable-next-line
          console.error(error);
        });
    },
    formatPrice(value) {
      const price = `S$ ${(value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,'))}`;
      return price;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
  },

  created() {
    this.getTransactions();
  },
  mounted() {
    this.totalRows = this.transactions.length;
  },
};
</script>
